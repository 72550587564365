<template>
  <el-card v-loading.body="isLoading" shadow="never">
    <template #header>
      <auth-card-header :heading="$t('page_reset_password.form_title')" />
    </template>
    <el-form
        :rules="formRules"
        size="medium"
        status-icon
        :model="form"
        ref="resetForm"
    >
      <el-form-item prop="email">
        <el-input :placeholder="$t('forms.email')" v-model="form.email" type="email"/>
      </el-form-item>
      <v-alert ref="alert" />
      <el-form-item>
        <v-wide-button size="large" type="primary" @click.native.prevent="handleResetPassword">
          {{ $t('page_reset_password.btn_submit') }}
        </v-wide-button>
      </el-form-item>
      <v-alert ref="alert"/>
    </el-form>
  </el-card>
</template>

<script>
import * as backgroundImage from '../../../assets/images/bg-reset.jpg'

import VWideButton from '../../components/ui/v-wide-button/VWideButton'
import VAlert from '../../components/ui/v-alert/VAlert'

import {resetPassword} from '../shared/services/auth'

import {mapState} from 'vuex'
import AuthCardHeader from '../shared/components/auth-card-header/AuthCardHeader'

export default {
  name: 'AuthResetPassword',
  components: {
    AuthCardHeader,
    VAlert,
    VWideButton
  },
  inject: ['changeLayoutImage'],
  created() {
    this.changeLayoutImage(backgroundImage)
  },
  data() {
    return {
      formRules: {
        email: [
          {required: true, message: this.$t('validators.field_required')},
          {type: 'email', message: this.$t('validators.incorrect_email')}
        ]
      },
      form: {
        email: ''
      }
    }
  },
  methods: {
    handleResetPassword() {
      this.$refs.resetForm.validate((valid) => {
        if (!valid) return
        resetPassword(this.form).then(() => {
          this.$refs.alert.showAlert({
            type: 'success',
            timeout: 5000,
            text: this.$t('alerts.reset_password_email_sent')
          })
        }).catch((e) => {
          this.$refs.alert.showAlert({
            type: 'error',
            timeout: 5000,
            text: this.$t(`alerts.${e.data}`)
          })
        })
      })
    }
  },
  computed: {
    ...mapState('loadingState', {
      isLoading: state => state.isLoading
    }),
  }
}
</script>

